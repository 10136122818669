import React from 'react';
import PropTypes from 'prop-types';
export function RamadanQuranUsage({ stat, index }) {
  return (
    <div
      className={`border ${
        index < 3 ? 'border-primary-4' : 'border-neutral-2'
      }  rounded-2xl p-5 flex flex-col items-center md:items-start`}
    >
      <div className="text-green-500 w-auto h-auto">{stat.icon}</div>
      <p className="text-accent mt-12 text-5xl font-semibold leading-[1.16] tracking-[-.2px]">
        {stat.stat_value}
      </p>
      <p className="text-neutral-7 text-gray-700">{stat.stat_title}</p>
    </div>
  );
}

RamadanQuranUsage.propTypes = {
  stat: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
