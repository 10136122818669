import React from 'react';
import { RamadanQuranUsage } from './RamadanQuranUsage';
import QuranIcon from '../../static/icons/ramadan-impact/quran-app-logo.inline.svg';
import HourGlassIcon from '../../static/icons/ramadan-impact/standwatch-icon.inline.svg';
import UserGroupIcon from '../../static/icons/ramadan-impact/user-group-icon.inline.svg';
import ClockIcon from '../../static/icons/ramadan-impact/clock-icon.inline.svg';
import AudioIcon from '../../static/icons/ramadan-impact/audio-time-icon.inline.svg';
import SearchIcon from '../../static/icons/ramadan-impact/search-icon.inline.svg';
import ShareIcon from '../../static/icons/ramadan-impact/share-icon.inline.svg';
import PropTypes from 'prop-types';
import Icon from '../components/ui/icon';
export function RamadanQuranUsageList({ listNo }) {
  const ramadan_1445_Quran_Stats = {
    title: 'Ramadan 1445',
    description:
      'Alhamdulillah! With Allah SWT’s grace, we touched great milestones during previous Ramadan (2024). Here is some of them!',
    quran_stats: [
      {
        icon: <HourGlassIcon />,
        stat_title: 'Hours of Quran Engagement',
        stat_value: '58,69,200',
        stat_quatifier: '',
      },
      {
        icon: <UserGroupIcon />,
        stat_title: 'Active Users',
        stat_value: '2.1M',
        stat_quatifier: 'M',
      },
      {
        icon: <ClockIcon />,
        stat_title: 'Times Users Used Quran App',
        stat_value: '28M',
        stat_quatifier: 'M',
      },
      {
        icon: <AudioIcon />,
        stat_title: 'Times Audio Listened',
        stat_value: '12M',
        stat_quatifier: 'M',
      },
      {
        icon: <SearchIcon />,
        stat_title: 'Searches Performed',
        stat_value: '2.7M',
        stat_quatifier: 'M',
      },
      {
        icon: <ShareIcon />,
        stat_title: 'Ayah Shared',
        stat_value: '132.4K',
        stat_quatifier: 'K',
      },
    ],
  };
  const ramadan_1445_to_1446_Quran_Stats = {
    title: 'Shawwal 1445-Shaban 1446',
    description:
      'Alhamdulillah! Our journey from Shawwal 2024 to Shaban 2025 has been filled with growth and <br>blessings. Let’s reflect on all that we’ve achieved!',
    quran_stats: [
      {
        icon: <HourGlassIcon />,
        stat_title: 'Hours of Quran Engagement',
        stat_value: '2,83,03,560',
      },
      {
        icon: <UserGroupIcon />,
        stat_title: 'Active Users',
        stat_value: '5.8M',
        stat_quatifier: 'M',
      },
      {
        icon: <ClockIcon />,
        stat_title: 'Times Users Used Quran App',
        stat_value: '157M',
        stat_quatifier: 'M',
      },
      {
        icon: <AudioIcon />,
        stat_title: 'Times Audio Listened',
        stat_value: '63M',
        stat_quatifier: 'M',
      },
      {
        icon: <SearchIcon />,
        stat_title: 'Searches Performed',
        stat_value: '16M',
        stat_quatifier: 'M',
      },
      {
        icon: <ShareIcon />,
        stat_title: 'Ayah Shared',
        stat_value: '791K',
        stat_quatifier: 'K',
      },
    ],
  };
  return (
    <>
      <div className="mt-16 :mt-24">
        <p className="px-6 lg:px-0 text-center font-extraBold text-4xl md:text-5xl leading-tight tracking-[-0.4px]">
          {listNo == 1 && ramadan_1445_Quran_Stats.title}
          {listNo == 2 && ramadan_1445_to_1446_Quran_Stats.title}
        </p>
        {listNo == 1 && (
          <p
            dangerouslySetInnerHTML={{
              __html: ramadan_1445_Quran_Stats.description,
            }}
            className="px-6 lg:px-0 mt-4 text-sm md:text-base text-center leading-[1.7] md:leading-normal tracking-[0.2px]"
          />
        )}
        {listNo == 2 && (
          <p
            dangerouslySetInnerHTML={{
              __html: ramadan_1445_to_1446_Quran_Stats.description,
            }}
            className="px-6 lg:px-0 mt-4 text-sm md:text-base text-center leading-[1.7] md:leading-normal tracking-[0.2px]"
          />
        )}
      </div>
      <div className="mt-16 px-6 md:px-0">
        <div className="flex justify-center items-center">
          <Icon className="fill-primary" icon={<QuranIcon />} />
          <p className="ml-3 text-2xl leading-relaxed font-semiBold">
            Al Quran{' '}
            <span className="hidden leading-relaxed md:inline">
              (Tafsir & By Word)
            </span>
          </p>
        </div>
        <div>
          <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 py-8">
            {listNo == 1 &&
              ramadan_1445_Quran_Stats.quran_stats.map((stat, index) => (
                <RamadanQuranUsage key={index} stat={stat} index={index} />
              ))}
            {listNo == 2 &&
              ramadan_1445_to_1446_Quran_Stats.quran_stats.map(
                (stat, index) => (
                  <RamadanQuranUsage key={index} stat={stat} index={index} />
                ),
              )}
          </div>
        </div>
      </div>
    </>
  );
}

RamadanQuranUsageList.propTypes = {
  listNo: PropTypes.number.isRequired,
};
