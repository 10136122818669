import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout.js';
import { CommonContext } from '../../context.js';
import Reviews from '../../components/Reviews';
import { RamadanImpactPageReviewTitle } from '../../components/ReviewTitle.js';
import { RamadanCountryUsageList } from '../../components/RamadanCountryUsageList.js';
import { RamadanQuranUsageList } from '../../components/RamadanQuranUsageList.js';
import DonateCallout from '../../components/DonateCallout';
import PropTypes from 'prop-types';
export const query = graphql`
  query DonateDataQuery {
    allUserReviews {
      nodes {
        reviewer
        stars
        reviewText
        avatar
      }
    }
  }
`;
const RamadanImpactPage = ({ location, data }) => {
  const reviews = data.allUserReviews.nodes;
  const utmPart =
    '/?utm_source=gtaf&utm_medium=page&utm_campaign=ramadan-2025&utm_term=cta-donate&utm_content=ramadan-impact';
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Ramadan Impact" location={location}>
        <section className="mt-10">
          <div className="flex flex-col items-center overflow-hidden">
            <div className="flex flex-col items-center mt-6 md:mt-14">
              <p className="text-center text-xl md:text-3xl leading-normal tracking-[-0.7px]">
                Ramadan Impact - 1445 🌙
              </p>
              <div className="text-center text-4xl md:text-7xl mt-4 leading-[1.06] tracking-[-2px] md:tracking-[-4px] font-semiBold lg:font-regular">
                <p>
                  How We Are{' '}
                  <span className="inline sm:hidden ">
                    {' '}
                    <br />{' '}
                  </span>{' '}
                  Bringing People <br />
                </p>
                <h1 className="text-primary font-semiBold">Closer To Allah</h1>
              </div>
            </div>
            <div className="relative w-full h-40 mt-5 overflow-hidden bg-[url('/icons/ramadan-impact/green-dot-bg-icon.inline.svg')]"></div>
          </div>
        </section>
        <section>
          <RamadanQuranUsageList listNo={1} />
        </section>
        <section className="bg-neutral-1 mt-8 md:mt-16">
          <RamadanCountryUsageList listNo={1} />
        </section>
        <section>
          <RamadanQuranUsageList listNo={2} />
        </section>
        <section className="bg-neutral-1 mt-8 md:mt-16">
          <RamadanCountryUsageList listNo={2} />
        </section>
        <div>
          {reviews.length ? (
            <Reviews reviews={reviews}>
              <RamadanImpactPageReviewTitle />
            </Reviews>
          ) : null}
        </div>
        <section className="my-24">
          <DonateCallout utmPart={utmPart} />
        </section>
      </Layout>
    </CommonContext.Provider>
  );
};
RamadanImpactPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
export default RamadanImpactPage;
