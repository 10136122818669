import React from 'react';
import PropTypes from 'prop-types';
export function RamadanCountryUsage({ widthPercentage, country }) {
  return (
    <div className="flex items-center mt-7 ">
      <div
        className="flex justify-between items-center rounded-md text-white bg-neutral-9"
        style={{
          width: `${widthPercentage}%`,
        }}
      >
        <div className="text-lg md:text-xl font-bold my-3 mx-3 md:mx-4">
          <span className="block md:hidden">{country.country_name_sm}</span>
          <span className="hidden md:block">{country.country_name}</span>
        </div>
        <span className="w-auto h-auto font-bold mr-3 md:mr-4">
          {country.icon}
        </span>
      </div>
      <p className="ml-3 md:ml-5 text-2xl font-bold">{country.value_in_text}</p>
    </div>
  );
}

RamadanCountryUsage.propTypes = {
  widthPercentage: PropTypes.number.isRequired,
  country: PropTypes.object.isRequired,
};
