import React from 'react';
import { RamadanCountryUsage } from './RamadanCountryUsage';
import IndonesiaFlagIcon from '../../static/icons/ramadan-impact/flag/indonesian-flag.inline.svg';
import IndiaFlagIcon from '../../static/icons/ramadan-impact/flag/indian-flag.inline.svg';
import BangladeshFlagIcon from '../../static/icons/ramadan-impact/flag/bangladeshi-flag.inline.svg';
import NigeriaFlagIcon from '../../static/icons/ramadan-impact/flag/nigerian-flag.inline.svg';
import MalaysiaFlagIcon from '../../static/icons/ramadan-impact/flag/malaysian-flag.inline.svg';
import UnitedKingdomFlagIcon from '../../static/icons/ramadan-impact/flag/united-kingdom-flag.inline.svg';
import UnitedStateFlagIcon from '../../static/icons/ramadan-impact/flag/united-state-flag.inline.svg';
import SaudiStateFlagIcon from '../../static/icons/ramadan-impact/flag/saudi-flag.inline.svg';
import PropTypes from 'prop-types';
export function RamadanCountryUsageList({ listNo }) {
  const countryWiseUsageList1 = [
    {
      country_name: 'Indonesia',
      country_name_sm: 'Indonesia',
      icon: <IndonesiaFlagIcon />,
      value: 3,
      value_in_text: '3M',
    },
    {
      country_name: 'India',
      country_name_sm: 'India',
      icon: <IndiaFlagIcon />,
      value: 2.9,
      value_in_text: '2.9M',
    },
    {
      country_name: 'Bangladesh',
      country_name_sm: 'Bangladesh',
      icon: <BangladeshFlagIcon />,
      value: 2.5,
      value_in_text: '2.5M',
    },
    {
      country_name: 'Nigeria',
      country_name_sm: 'Nigeria',
      icon: <NigeriaFlagIcon />,
      value: 2.3,
      value_in_text: '2.3M',
    },
    {
      country_name: 'Malaysia',
      country_name_sm: 'Malaysia',
      icon: <MalaysiaFlagIcon />,
      value: 1.6,
      value_in_text: '1.6M',
    },
    {
      country_name: 'United Kingdom',
      country_name_sm: 'UK',
      icon: <UnitedKingdomFlagIcon />,
      value: 1.3,
      value_in_text: '1.3M',
    },
    {
      country_name: 'United States',
      country_name_sm: 'USA',
      icon: <UnitedStateFlagIcon />,
      value: 1.2,
      value_in_text: '1.2M',
    },
  ];
  const countryWiseUsageList2 = [
    {
      country_name: 'Indonesia',
      country_name_sm: 'Indonesia',
      icon: <IndonesiaFlagIcon />,
      value: 17,
      value_in_text: '17M',
    },
    {
      country_name: 'Bangladesh',
      country_name_sm: 'Bangladesh',
      icon: <BangladeshFlagIcon />,
      value: 15,
      value_in_text: '15M',
    },
    {
      country_name: 'India',
      country_name_sm: 'India',
      icon: <IndiaFlagIcon />,
      value: 14,
      value_in_text: '14M',
    },

    {
      country_name: 'Nigeria',
      country_name_sm: 'Nigeria',
      icon: <NigeriaFlagIcon />,
      value: 13,
      value_in_text: '13M',
    },
    {
      country_name: 'Malaysia',
      country_name_sm: 'Malaysia',
      icon: <MalaysiaFlagIcon />,
      value: 9.7,
      value_in_text: '9.7M',
    },
    {
      country_name: 'Saudi Arabia',
      country_name_sm: 'KSA',
      icon: <SaudiStateFlagIcon />,
      value: 8.4,
      value_in_text: '8.4M',
    },
    {
      country_name: 'United States',
      country_name_sm: 'USA',
      icon: <UnitedStateFlagIcon />,
      value: 6.5,
      value_in_text: '6.5M',
    },
  ];
  return (
    <div className="pt-16 md:pt-24">
      <div className="relative w-full h-full overflow-hidden bg-[url('/icons/ramadan-impact/map-bg-icon.inline.svg')]">
        <div className="max-w-6xl mx-6 xl:mx-auto">
          <p className="text-accent  text-3xl md:text-5xl md:text-center leading-tight font-extraBold tracking-[-0.4px]">
            Top Countries By Usage
          </p>
          <div className="mt-8 md:mt-16 max-w-6xl mx-auto">
            {listNo == 1 &&
              countryWiseUsageList1.map((country) => (
                <RamadanCountryUsage
                  key={country.name}
                  widthPercentage={(country.value / 3.0) * 100}
                  country={country}
                />
              ))}
            {listNo == 2 &&
              countryWiseUsageList2.map((country) => (
                <RamadanCountryUsage
                  key={country.name}
                  widthPercentage={(country.value / 17.0) * 100}
                  country={country}
                />
              ))}
          </div>
        </div>
      </div>
      {listNo == 1 && (
        <p className="mx-6 xl:mx-auto mt-16 md:mt-24 pb-6 md:pb-16  text-accent text-center font-normal md:font-semiBold">
          And we continued impacting muslims worldwide through our Quran app
          round the year. Here is some sneak peak:
        </p>
      )}
    </div>
  );
}
RamadanCountryUsageList.propTypes = {
  listNo: PropTypes.number.isRequired,
};
